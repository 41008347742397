import { render, staticRenderFns } from "./SquareGame.vue?vue&type=template&id=d42784ec&scoped=true&"
import script from "./SquareGame.vue?vue&type=script&lang=ts&"
export * from "./SquareGame.vue?vue&type=script&lang=ts&"
import style0 from "./SquareGame.vue?vue&type=style&index=0&id=d42784ec&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d42784ec",
  null
  
)

export default component.exports