import { render, staticRenderFns } from "./CharacterBubble.vue?vue&type=template&id=22346176&scoped=true&"
import script from "./CharacterBubble.vue?vue&type=script&lang=ts&"
export * from "./CharacterBubble.vue?vue&type=script&lang=ts&"
import style0 from "./CharacterBubble.vue?vue&type=style&index=0&id=22346176&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22346176",
  null
  
)

export default component.exports