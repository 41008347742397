import { render, staticRenderFns } from "./SquareQuestItemsStatus.vue?vue&type=template&id=1fce4788&scoped=true&"
import script from "./SquareQuestItemsStatus.vue?vue&type=script&lang=ts&"
export * from "./SquareQuestItemsStatus.vue?vue&type=script&lang=ts&"
import style0 from "./SquareQuestItemsStatus.vue?vue&type=style&index=0&id=1fce4788&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fce4788",
  null
  
)

export default component.exports